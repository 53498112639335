.ReactModal__Overlay {
  z-index: 10;
}

.modal-newsletter-signup-container {
  width: 90%;
  max-width: 35rem;
  margin: 50vh auto;
  transform: translateY(-50%);
  background-color: white;
  border: 5px solid var(--primary-color);
  outline: none;
  position: relative;
  background-size: cover;
}

.modal__newsletter-signup-wrapper {
  padding: 1rem 2rem;
}

.modal__close-button {
  position: absolute;
  right: 2rem;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.3rem;
  cursor: pointer;
}

.modal__newsletter-signup-title {
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .modal__newsletter-signup-wrapper {
    padding: 4rem;
  }

  .modal__newsletter-signup-title {
    font-size: 2.2rem;
  }
}
