@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 100%;
  height: 100%;
  border: 0.6rem solid #f3f3f3;
  border-top: 0.6rem solid var(--primary-color);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loading-spinner--small {
  border: 0.25rem solid #f3f3f3;
  border-top: 0.25rem solid var(--primary-color);
}
