.newsletter-signup-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.newsletter-signup-description {
  margin: 0 0 1rem;
}

.newsletter-signup-form {
  padding-top: 1rem;
}

.newsletter-signup-title {
  color: var(--primary-color)
}

.newsletter-signup-inputs-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 19rem;
  margin: 1rem auto 0;
}

.newsletter-signup-form-input {
  margin: 0;
  padding: .6rem .9rem;
  flex: 0 0 55%;
}

.newsletter-signup-spinner-submit-button-wrapper {
  margin: 0 0 0 0.6rem;
  margin: 0.6rem 0 0;
  flex: 0 0 36%;
}

.newsletter-signup-form-button {
  width: 100%;
  padding: .6rem .9rem;
  cursor: pointer;
  background-color: var(--primary-color-light);
  border: 1px solid var(--primary-color-light);
  color: white;
  font-weight: 600;
}

.newsletter-signup-form-button:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.newsletter-signup-spinner-wrapper {
  width: 1rem;
  height: 1rem;
  margin: 0 auto 0.6rem;
}

.newsletter-signup-form-status-message-wrapper {
  min-height: 1.6rem;
  padding: 0.3rem 0 0;
  font-size: 0.8rem;
}

.newsletter-signup-form-success-message {
  margin: 0;
  color: var(--primary-color-dark);
}

.newsletter-signup-form-error-message {
  margin: 0;
  color: darkred;
}

.newsletter-signup__legal-disclaimer {
  font-size: .625rem;
}

.newsletter-signup__legal-disclaimer-unsubscribe {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .newsletter-signup-inputs-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 27.5rem;
  }

  .newsletter-signup-spinner-submit-button-wrapper {
    margin: 0 0 0 0.6rem;
  }
}

@media screen and (min-width: 992px) {
  .newsletter-signup-description {
    padding: 0 10%;
  }

  .newsletter-signup-inputs-wrapper {
    max-width: 22.5rem;
  }

  .footer-wrapper {
    padding-right: 5rem;
  }
}

@media only screen and (min-width: 1275px) {
  .newsletter-signup-form-input {
    width: 40%;
  }
}
