.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  background-color: white;
}

.header__logo-navbar-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 1rem 3rem;
  transition: background-color 1s;
}

.header__logo-navbar-wrapper--scrolled {
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 8%);
}

.header__logo-navbar-wrapper--homepage-normal {
  position: absolute;
  width: calc(100% - 96px);
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: blur(5rem);
  -webkit-backdrop-filter: blur(5rem);
}

.header__logo-navbar-wrapper--homepage-scrolled {
  background-color: white;
}

.header__logo-navbar-wrapper--homepage-normal > .header__content-wrapper > .navbar > .navbar__link:hover,
.header__logo-navbar-wrapper--homepage-normal > .header__content-wrapper > .navbar > .navbar__services > .navbar__link:hover {
  color: white;
}

.header__logo-navbar-wrapper--homepage-normal > .header__content-wrapper > .navbar > .navbar__link:after,
.header__logo-navbar-wrapper--homepage-normal > .header__content-wrapper > .navbar > .navbar__services > .navbar__link:after {
  background-color: white;
}

.header__logo-navbar-wrapper--homepage-scrolled > .header__content-wrapper > .navbar > .navbar__link:hover,
.header__logo-navbar-wrapper--homepage-scrolled > .header__content-wrapper > .navbar > .navbar__services > .navbar__link:hover {
  color: var(--primary-color);
}

.header__logo-navbar-wrapper--homepage-scrolled > .header__content-wrapper > .navbar > .navbar__link:after,
.header__logo-navbar-wrapper--homepage-scrolled > .header__content-wrapper > .navbar > .navbar__services > .navbar__link:after {
  background-color: var(--primary-color);
}

.header__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70rem;
}

.center {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
  max-width: 15rem;
}

.brand-lettering__wrapper {
  display: flex;
  flex-direction: column;
}

.brand-lettering__content {
  margin: 0;
  text-decoration: none;
  color: var(--font-color);
}

.header__main-name {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: .2rem 0;
}

@media screen and (min-width: 650px) {
  .header__content-wrapper {
    flex-direction: row;
  }

  .brand-lettering__wrapper {
    flex-direction: unset;
    justify-content: flex-start;
  }

  .header__main-name {
    letter-spacing: 3px;
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .header__logo-navbar-wrapper {
    padding: 1rem 0;
  }

  .header__logo-navbar-wrapper--homepage-normal {
    width: 100%;
    min-width: 100%;
  }

  .header__content-wrapper {
    margin: 0 auto;
  }

  .brand-lettering__content > h2 {
    margin-left: 3rem;
  }
}
