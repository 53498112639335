.footer {
  position: relative;
  width: 100%;
  margin-top: 2rem;
  background-color: var(--secondary-color);
}

.footer p {
  line-height: 1.5;
}

.footer__title {
  font-weight: 700;
  color: var(--primary-color);
}

.footer__content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem 1rem;
}

.footer__contacts-wrapper {
  position: relative;
  margin: 2rem 0 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--primary-color);
  text-align: center;
}

.footer__social {
  padding: .4rem 0 .4rem 1.4rem;
}

.footer__social-links {
  padding-right: 1.5rem;
  text-decoration: none;
}

.footer__social-links:last-of-type {
  padding-right: 0;
}

.footer__complaints-book-wrapper {
  display: flex;
  margin-bottom: 1rem;
}

.footer__complaints-book {
  margin: 0 auto;
}

.footer__copyright {
  margin: 0;
  padding-bottom: .5rem;
  font-size: 0.8rem;
  text-align: center;
}

.footer__legal-links-wrapper {
  text-align: center;
  padding-bottom: 1.5rem;
}

.footer__legal-links {
  font-size: 0.625rem;
  text-decoration: none;
  color: black;
}

.footer__legal-links:hover {
  text-decoration: underline;
}

@media screen and (min-width: 992px) {

  .footer__content-wrapper {
    flex-direction: row-reverse;
    max-width: 65rem;
    margin: 0 auto;
    padding: 5rem 0 2rem;
  }

  .footer__contacts-wrapper {
    min-height: 11rem;
    margin: 0;
    margin-right: auto;
    padding: 0;
    padding-left: 5rem;
    border-top: none;
    text-align: left;
  }

  .footer__social {
    position: absolute;
    bottom: 2rem;
    padding: 0;
  }
}

@media only screen and (min-width: 1275px) {
  .footer__content-wrapper {
    max-width: 75rem;
  }
}
