@font-face {
  font-family: 'LeMajor';
  src: url('./assets/fonts/LeMajor-Regular.otf') format('opentype'),
       url('./assets/fonts/LeMajor-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MinionPro';
  src: url('./assets/fonts/MinionPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MinionPro';
  src: url('./assets/fonts/MinionPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MinionPro';
  src: url('./assets/fonts/MinionPro-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MinionPro';
  src: url('./assets/fonts/MinionPro-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

:root {
  --primary-color: #B48835;
  --secondary-color: rgb(239 226 209);
  --secondary-color-light: #F0EDE8;
  --primary-color-light: #D9B46D;
  --primary-color-dark: #856030;
  --contrast-green: #143429;
  --font-color: #090401;
}

* {
  /* box-sizing: border-box; */
  transition: all .5s;
}

body {
  margin: 0;
  color: var(--font-color);
  font-family: 'MinionPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 120%;
  background-size: contain;
}

p, li {
  font-size: 1rem;
  line-height: 1.8;
}

h1 {
  font-size: 1.7rem;
  font-family: 'LeMajor', sans-serif;
  color: var(--contrast-green);
}

h2 {
  font-size: 1.4rem;
  text-transform: uppercase;
}

h4 {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  font-size: 1rem;
}

::selection {
  background-color: var(--primary-color);
  color: white;
}

.cta {
  min-height: 3rem;
  margin-top: 1rem;
  padding: .5rem .75rem;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 0.1rem solid var(--primary-color);
  color: var(--primary-color);
}

.cta:hover {
  background-color: var(--primary-color);
  color: white;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.7rem;
  }
}
