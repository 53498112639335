.hero-and-content-double-card {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.hero-and-content-double-card__hero {
  padding: 0;
}

.hero-and-content-double-card__hero > img {
  margin: auto;
  width: 100%;
}

.hero-and-content-double-card__content {
  text-align: center;
}

.hero-and-content-double-card__content > h2 {
  padding: 1rem 0;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  text-transform: capitalize;
}

.hero-and-content-double-card__content > a > button {
  margin-bottom: 1rem;
}

@media screen and (min-width: 480px) {
  .hero-and-content-double-card__hero {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .hero-and-content-double-card {
    flex-direction: row;
    padding: 2rem;
    justify-content: center;
    align-items: center;  
  }

  .hero-and-content-double-card__hero {
    padding: 0;
    min-width: 50%;
    max-width: 50%;
  }

  .hero-and-content-double-card__hero > img {
    width: 80%;
  }

  .hero-and-content-double-card__content {
    padding: 2rem;
    text-align: left;
  }

  .hero-and-content-double-card__content > h2 {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 992px) {
  .hero-and-content-double-card__content {
    padding-right: 4rem;
  }
}
