.navbar {
  display: flex;
  gap: 2rem;
  margin-top: .5rem;
  position: relative;
}

.navbar__link {
  color: var(--font-color);
  width: fit-content;
  line-height: 1;
  font-size: 1.2rem;
}

.navbar__link:hover {
  text-decoration: none;
  color: var(--primary-color);
}

.navbar__link:after {
  background-color: var(--primary-color);
  content: '';
  display: block;
  height: .1em;
  margin-left: calc(.1em * -1);
  margin-top: .2em;
  transition: width .5s;
  width: 0;
}

.navbar__link:hover:after {
  width: calc(100% + .1em * 2);
}

.navbar__services {
  position: relative;
  display: flex;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1000;
  color: var(--primary-color);
}

.navbar__services:hover .submenu {
  display: flex;
}

.submenu > .navbar__link {
  font-size: 1rem;
  width: max-content;
}

@media screen and (min-width: 650px) {
  .navbar {
    margin-top: 0;
  }
}
