.whatsapp-button {
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  z-index: 5;
}

.whatsapp-button__icon {
  width: 4rem;
}

.whatsapp-button__icon:hover {
  transform: scale(1.05);
}
